<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>创建短信通知</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/msmnotification')"
          >返回</el-button
        >
      </div>
      <!-- card header -->
      <!-- card content -->
      <el-form
        :model="formData"
        :rules="rules"
        label-width="100px"
        ref="formData"
      >
        <el-form-item label="姓名" prop="name">
          <el-input placeholder="请输入姓名" v-model="formData.name">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input placeholder="请输入手机号" v-model="formData.phone">
          </el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input placeholder="请输入公司名称" v-model="formData.companyName">
          </el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select
            v-model="formData.deviceType"
            @focus="focusInput"
            placeholder="请选择设备类型"
            clearable
            multiple
            filterable
          >
            <el-option
              v-for="item in deviceTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-col :span="4" v-if="isLESSOR">
              <el-select
                clearable
                v-model="authValue['LESSOR'].rightText"
                @change="changeUnit"
                multiple
                filterable
                placeholder="请选择租赁单位"
              >
                <el-option
                  v-for="item in unitCompanys"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col> -->
        <!-- <el-pagination
              layout="prev, pager, next"
              small
              :total="total"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
            >
            </el-pagination> -->

        <!-- 表单按钮 -->
        <div v-if="formBtns && formBtns.length" class="button-group">
          <el-button
            v-for="(item, index) in formBtns"
            :key="index"
            :type="item.type"
            :style="{ width: item.width || '70px' }"
            class="form-button"
            @click="submitFn(item.content)"
          >
            <!-- <i v-if="item.icon" :class="item.icon"></i> -->
            <span> {{ item.content }}</span>
          </el-button>
        </div>
      </el-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { brequest } from '@/utils/brequest'
import { addMsm } from '@/api/detection'
//  ,
// import { DATA_RIGHT_TYPE } from '@/utils/params'
// import { addUserDataRight } from '@/api/users'
export default {
  methods: {
    submitFn(content) {
      console.log(this.formData)

      if (content === '重置') {
        this.$refs.formData.resetFields()
      } else {
        this.$refs.formData.validate((isSuccess) => {
          if (isSuccess) {
            this.$loading({
              text: '数据添加中'
            })
            this.addDataFn()
          }
        })
      }
    },
    addDataFn() {
      this.formData.deviceType = this.formData.deviceType.join(',')
      addMsm(this.formData).then((res) => {
        console.log(res.data, '创建')
        if (res.code == 200) {
          this.$message({
            message: '数据添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        } else if (res.code == -1) {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      setTimeout(() => {
        this.$loading().close()
      }, 1000)
    },

    async querydeviceTypeList() {
      let ret = await brequest({
        url: '/check/querySysData?dataType=equipment_type',
        data: {}
      })
      this.deviceTypeList = ret
    }
  },

  mounted() {
    this.querydeviceTypeList()
  },

  data() {
    return {
      // 表单配置
      // form: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,

      formData: {
        name: '',
        phone: '',
        companyName: '',
        deviceType: ''
      },
      deviceTypeList: [],
      rules: {
        name: [{ required: true, message: '姓名必填', trigger: 'blur' }],
        phone: [
          { required: true, message: '手机号必填', trigger: 'blur' },
          {
            pattern: /^1[345789]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'blur'
          }
        ],
        companyName: [
          { required: true, message: '公司名称必填', trigger: 'blur' }
        ],

        deviceType: [
          { required: true, message: '设备类型必填', trigger: 'blur' }
        ]
      },

      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
      // restaurants: []
    }
  }
}
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  justify-content: center;
  .form-button {
    margin: 0 20px;
  }
}
.rightType {
  width: 100%;
  display: flex;
}
</style>
