<template>
  <div class="history">
    <el-card class="box-card">
      <div slot="header">
        <el-row style="display: flex; align-items: center">
          <el-col :span="4">
            <span>检查业务台账</span>
          </el-col>
          <el-col :span="7"> &nbsp; </el-col>
          <!-- 时间选择 -->
          <el-col :span="8">
            <el-date-picker
              v-model="pickerDate"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :editable="false"
              unlink-panels
              :picker-options="pickerOptions"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-col>
          <!-- 下载 -->
          <el-col :span="3">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              :loading="deviceExportLoading"
              @click="deviceExport"
              >检查单设备</el-button
            >
          </el-col>
          <el-col :span="1"> &nbsp; </el-col>
          <el-col :span="1">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              :loading="downloadLoading"
              @click="download"
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 筛选 -->
      <el-row>
        <!-- 搜索条件 -->
        <el-col :span="3">
          <el-input
            v-model="partyaName"
            placeholder="请输入甲方名称"
          ></el-input>
        </el-col>
        <!-- <el-col :span="1">&nbsp;</el-col> -->
        <el-col :span="4">
          <el-input
            v-model="projectName"
            placeholder="请输入项目名称"
          ></el-input>
        </el-col>
        <!-- <el-col :span="1">&nbsp;</el-col> -->
        <el-col :span="4">
          <el-input v-model="addr" placeholder="请输入地区"></el-input>
        </el-col>
        <!-- <el-col :span="1">&nbsp;</el-col> -->
        <el-col :span="4">
          <el-input
            v-model="applyProjectname"
            placeholder="请输入工地项目名称"
          ></el-input>
        </el-col>
        <!-- <el-col :span="1">&nbsp;</el-col> -->
        <el-col :span="4">
          <el-input
            v-model="deviceCheckcode"
            placeholder="请输入设备编号"
          ></el-input>
        </el-col>
        <!-- <el-col :span="1">&nbsp;</el-col> -->
        <el-col :span="4">
          <!-- <el-input
            v-model="deviceName"
            placeholder="请输入设备名称"
          ></el-input> -->
          <el-select
            class="select"
            clearable
            v-model="deviceType"
            filterable
            placeholder="请选择设备类型"
          >
            <el-option
              v-for="item in deviceTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>

        <!-- 搜索 -->
        <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col>
      </el-row>
      <!-- 筛选 -->
      <!-- <TzTable :tableData="tableDatas" :columns="columns" /> -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        ref="table"
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column type="index" align="center" :index="1">
          <template #header>
            <i
              class="el-icon-setting"
              style="font-size: 22px; cursor: pointer"
              @click="dialogVisible = true"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, i) in column"
          :key="i + item.prop"
          :prop="item.prop"
          :label="item.label"
          sortable
          width="110"
        >
        </el-table-column>
        <template v-if="problemSize > 0">
          <el-table-column
            v-for="(item, i) in problemColumn"
            :key="i + item.prop"
            :prop="item.prop"
            :label="item.label"
            sortable
          >
          </el-table-column
        ></template>

        <el-table-column
          v-for="(item, i) in columnsComputed"
          :key="i + item.prop"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="电子芯片"
          align="center"
          prop="electronicChipPhoto"
        >
          <template slot-scope="scope">
            <span
              v-for="(photo, idx) in splitJoin(scope.row.electronicChipPhoto)"
              :key="idx + 'photo'"
              style="margin-left: 10px"
            >
              <ImagePreview
                :src="getImgUrl(photo)"
                width="60px"
                height="60px"
              />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- dialog -->
      <el-dialog title="字段配置" :visible.sync="dialogVisible" width="30%">
        <div>
          <div>选择显示字段</div>
          <div v-for="(item, i) in columns" :key="i">
            <el-checkbox v-model="item.show">{{ item.label }}</el-checkbox>
          </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="tochangeTrue">确 定</el-button>
        </span> -->
      </el-dialog>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 分页 -->
    </el-card>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
import {
  listDeviceQuestion,
  deviceQuestionExport,
  deviceExport
} from '@/api/inspect'
import { querySysData } from '@/api/detection'
export default {
  data() {
    return {
      partyaName: '', //甲方名称
      deviceName: '', //搜索的设备名称
      deviceType: null,
      deviceCheckcode: '', //设备编号
      projectName: '', // 项目名称
      applyProjectname: '', //工地项目名称
      addr: '', //地区
      dialogVisible: false,
      downloadLoading: false,
      deviceExportLoading: false,
      pictLoading: false,
      deviceTypeList: [],
      offset: '', //当前起始位置（page-1）*limit
      page: 1, //当前页
      limit: '10', //
      total: 0,
      problemSize: '', //问题数
      // 表格列
      column: [
        {
          label: '项目名称',
          prop: 'programName'
        },
        {
          label: '工地序号',
          prop: 'projectNameNum'
        },
        {
          prop: 'quarter',
          label: '季度'
        },
        {
          prop: 'projectName',
          label: '工程名称'
        },
        {
          prop: 'checkDate',
          label: '检查时间'
        },
        {
          prop: 'place',

          label: '地点'
        },
        {
          prop: 'constructionUnit',
          label: '施工单位'
        },
        {
          prop: 'constructionControlUnit',
          label: '监理单位'
        },
        {
          prop: 'deviceNum',
          label: '出厂编号'
        },
        {
          prop: 'partyaName',
          label: '甲方单位'
        },
        {
          prop: 'leasingUnit',
          label: '租赁单位'
        },
        {
          prop: 'installUnit',
          label: '安装单位'
        },
        {
          prop: 'maintenanceUnit',
          label: '维保单位'
        },
        {
          prop: 'createTime',
          label: '生产日期'
        },
        {
          prop: 'createUnit',
          label: '生产单位'
        },
        {
          prop: 'checkNum',
          label: '检查编号'
        },
        {
          prop: 'seatNo',
          label: '机位编号'
        },
        {
          prop: 'deviceModel',
          label: '设备及型号'
        },

        {
          prop: 'type',
          label: '类型'
        },
        {
          prop: 'anun',
          label: 'A数量'
        },
        {
          prop: 'bnum',
          label: 'B数量'
        },
        {
          prop: 'cnum',
          label: 'C数量'
        },
        {
          prop: 'othernum',
          label: '其他隐患数量'
        },
        {
          prop: 'otherScore',
          label: '其他隐患总分'
        },
        {
          prop: 'totalHiddenDangers',
          label: '隐患总数'
        },
        {
          prop: 'totalScore',
          label: '评分'
        },
        {
          prop: 'isDangerous',
          label: '危险设备'
        },
        {
          prop: 'checkUser',
          label: '检查人员'
        },
        {
          prop: 'entryStaff',
          label: '录入人员'
        },
        // {
        //   prop: 'deviceId',
        //   label: '设备id'
        // },
        {
          prop: 'problems',
          label: '问题总结'
        },
        {
          prop: 'defectContent',
          label: '缺陷内容'
        }
      ],
      problemColumn: [],
      columns: [],
      tableData: [],
      pickerDate: [], //日期 时间戳
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  mounted() {
    this.listDeviceQuestion()
  },
  created() {
    this.defaultTime()
    this.querySysDataFn()
    // this.listDeviceQuestion()
  },
  computed: {
    columnsComputed() {
      return this.columns.filter((item) => {
        return item.show
      })
    }
  },
  methods: {
    // 默认时间
    defaultTime() {
      if (this.pickerDate.length == 0) {
        // 默认当前日期之前365天
        let sdate = new Date()
        let interval = -365 //间隔天数
        let caret = '-' //日期分隔符
        let strYear = sdate.getFullYear()
        let strDay = sdate.getDate()
        let strMonth = sdate.getMonth() + 1
        let patt1 = /^\d{4}-([0-1]?[0-9])-([0-3]?[0-9])$/ //判断输入的日期是否符合格式正则表达式
        if (!(sdate && typeof sdate == 'string' && patt1.test(sdate))) {
          sdate = new Date() //不满足日期的则使用当前年月日
        }
        interval = isNaN(parseInt(interval)) ? 0 : parseInt(interval) //若没有输入间隔，则使用当前日
        caret = caret && typeof caret == 'string' ? caret : ''
        let gdate = new Date(sdate).getTime() //获取指定年月日
        gdate = gdate + 1000 * 60 * 60 * 24 * interval //加减相差毫秒数
        let speDate = new Date(gdate) //获取指定好毫秒数时间
        let preYear = speDate.getFullYear()
        let preMonth = speDate.getMonth() + 1
        let preDay = speDate.getDate()
        preMonth = preMonth < 10 ? '0' + preMonth : preMonth
        preDay = preDay < 10 ? '0' + preDay : preDay
        let preDate = preYear + caret + preMonth + caret + preDay
        this.pickerDate = [preDate, strYear + '-' + strMonth + '-' + strDay]
      }
    },
    // 获取设备类型
    querySysDataFn() {
      querySysData().then((res) => {
        this.deviceTypeList = res.data
      })
    },
    handleClick(row) {
      console.log(row)
    },
    handleSizeChange(val) {
      this.limit = val
      // console.log(this.limit, this.page)
      this.listDeviceQuestion()
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.page = val
      // console.log(this.limit, this.page)
      this.listDeviceQuestion()
      // console.log(`当前页: ${val}`)
    },
    //选择日期事件
    handleDateChange(pickerDate) {
      if (pickerDate == null) {
        this.pickerDate = []
      }
    },
    // 点击搜索
    search() {
      this.page = 1
      console.log(this.partyaName, this.deviceName, this.projectName)
      this.listDeviceQuestion()
    },
    listDeviceQuestion() {
      this.pictLoading = true
      var data = {
        // deviceName: this.deviceName,
        deviceType: this.deviceType,
        applyProjectname: this.applyProjectname,
        deviceCheckcode: this.deviceCheckcode,
        addr: this.addr,
        partyaName: this.partyaName,
        projectName: this.projectName,
        startTime: this.pickerDate ? this.pickerDate[0] || '' : '',
        endTime: this.pickerDate ? this.pickerDate[1] || '' : '',
        offset: (this.page - 1) * this.limit,
        limit: this.limit
      }

      listDeviceQuestion(data).then((res) => {
        this.pictLoading = false
        this.columns = []
        this.problemColumn = []
        this.total = res.data.total
        this.tableData = res.data.rows
        this.problemSize = res.data.maxSize
        console.log(res.data.total)
        let problem = res.data.problem
        if (res.data.total == 0) {
          this.$message({
            message: '暂无数据'
          })
        }

        //问题
        for (let index = 0; index < this.problemSize; index++) {
          let proObj = {
            label: '问题' + (index + 1),
            prop: (index + 1).toString()
          }
          this.problemColumn.push(proObj)
        }
        // 不固定表头
        problem.forEach((element) => {
          let obj = {
            label: element,
            prop: element,
            show: false
          }
          this.columns.push(obj)
        })

        this.tableData.forEach((item) => {
          Object.assign(item, item.position)
          Object.assign(item, item.problem)
        })
        // console.log(this.tableData)
      })
    },

    // 字符串转数组
    splitJoin(str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    // 图片地址拼接
    formatJson(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].filepath) {
          data[i].filepath = this.getImgUrl(data[i].filepath)
        }
      }
      return data
    },
    deviceExport() {
      this.deviceExportLoading = true
      var data = {
        deviceType: this.deviceType, //设备类型
        deviceCheckcode: this.deviceCheckcode,
        applyProjectname: this.applyProjectname,
        addr: this.addr,
        partyaName: this.partyaName,
        projectName: this.projectName,
        startTime: this.pickerDate ? this.pickerDate[0] || '' : '',
        endTime: this.pickerDate ? this.pickerDate[1] || '' : ''
      }
      deviceExport(data).then((res) => {
        fileDownload(res, '检查单设备.xlsx')
        this.deviceExportLoading = false
      })
    },
    download() {
      this.downloadLoading = true
      var data = {
        deviceType: this.deviceType, //设备类型
        deviceCheckcode: this.deviceCheckcode,
        applyProjectname: this.applyProjectname,
        addr: this.addr,
        partyaName: this.partyaName,
        projectName: this.projectName,
        startTime: this.pickerDate ? this.pickerDate[0] || '' : '',
        endTime: this.pickerDate ? this.pickerDate[1] || '' : ''
      }
      deviceQuestionExport(data).then((res) => {
        this.downloadLoading = false

        fileDownload(res, '检查业务台账.xlsx')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: cornflowerblue;
  text-decoration: none;
}
</style>
