/* eslint-disable prettier/prettier */
/* eslint-disable no-console */

import request from '@/utils/request'
// import qs from 'qs'
// 车辆列表
export const pageListCars = () => {
  return request({
    url: '/gps-cars-user/pageListCars',
    method: 'get',
    headers: {
      'content-type': 'application/json'
    }
  })
}
//司机列表
export const pageListDrivers = (getData) => {
  return request({
    url: '/gps-cars-user/queryUserList',
    method: 'get',
    headers: {
      'content-type': 'application/json'
    },
    params: getData
  })
}
// 页面列表
export const pageList = (data) => {
  return request({
    url: '/gps-cars-user/pageList',
    method: 'get',
    headers: {
      'content-type': 'application/json'
    },
    params: data
  })
}
//新增车辆设置
export const addCarSet = (data) => {
  return request({
    url: '/gps-cars-user/add ',
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    data
  })
}
//修改车辆设置
export const updateCarSet = (data) => {
  return request({
    url: '/gps-cars-user/updateById',
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    data
  })
}
//删除
export const deleteCarSet = (data) => {
  return request({
    url: '/gps-cars-user/deleteById',
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    data
  })
}